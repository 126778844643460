"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundToNearestFontScale = exports.getFontSizeForLayoutSize = exports.parseString = exports.parseStyleFunction = void 0;
const react_native_1 = require("react-native");
const style_function_helpers_1 = require("./style-function-helpers");
function parseStyleFunction(functionString, value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    if (!functionString) {
        return;
    }
    if (value === undefined) {
        return;
    }
    switch (functionString) {
        case "hairlineWidth":
            return react_native_1.StyleSheet.hairlineWidth;
        case "roundToNearestPixel":
            return parseFloat(value, react_native_1.PixelRatio.roundToNearestPixel);
        case "getPixelSizeForLayoutSize":
            return parseFloat(value, react_native_1.PixelRatio.getPixelSizeForLayoutSize);
        case "getFontSizeForLayoutSize":
            return parseFloat(value, getFontSizeForLayoutSize);
        case "roundToNearestFontScale":
            return parseFloat(value, roundToNearestFontScale);
        case "platformColor":
            return parseString(value, platformColor);
        case "platform":
            return parseString(value, platform);
    }
    throw new Error(`Unknown functionString: ${functionString}`);
}
exports.parseStyleFunction = parseStyleFunction;
function parseString(input, callback = ((n) => n)) {
    return callback((0, style_function_helpers_1.isRuntimeFunction)(input)
        ? parseStyleFunction(...(0, style_function_helpers_1.matchRuntimeFunction)(input))
        : input);
}
exports.parseString = parseString;
function parseFloat(input, callback) {
    const value = Number.parseFloat((0, style_function_helpers_1.isRuntimeFunction)(input)
        ? parseStyleFunction(...(0, style_function_helpers_1.matchRuntimeFunction)(input))
        : input);
    if (Number.isNaN(value))
        return;
    return callback(value);
}
function getFontSizeForLayoutSize(layoutSize) {
    return Math.round(layoutSize * react_native_1.PixelRatio.getFontScale());
}
exports.getFontSizeForLayoutSize = getFontSizeForLayoutSize;
function roundToNearestFontScale(layoutSize) {
    const ratio = react_native_1.PixelRatio.getFontScale();
    return Math.round(layoutSize * ratio) / ratio;
}
exports.roundToNearestFontScale = roundToNearestFontScale;
function platformColor(color) {
    // RWN does not implement PlatformColor
    // https://github.com/necolas/react-native-web/issues/2128
    return react_native_1.PlatformColor ? (0, react_native_1.PlatformColor)(color) : color;
}
function platform(platformValues) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const specifics = Object.fromEntries(platformValues
        .trim()
        .split(/\s+/)
        .map((platformValue) => {
        // Edge case:
        //   platform(android:platformColor(@android:color/holo_blue_bright))
        // Sometimes the value can has a colon, so we need to collect all values
        // and join them
        let [platform, ...tokens] = platformValue.split(":");
        if (!tokens) {
            tokens = [platform];
            platform = "default";
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let value = tokens.join("");
        const numberValue = Number.parseFloat(value);
        value = Number.isFinite(numberValue) ? numberValue : parseString(value);
        return [platform, value];
    }));
    return react_native_1.Platform.select(specifics);
}
