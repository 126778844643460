"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.matchRuntimeFunction = exports.isRuntimeFunction = void 0;
/**
 * These need to be in a separate file as they are also used by Babel
 *
 * The main file imports 'react-native' which needs to be compiled
 */
function isRuntimeFunction(input) {
    if (typeof input !== "string")
        return false;
    return (input === "hairlineWidth()" ||
        input.startsWith("roundToNearestPixel(") ||
        input.startsWith("getPixelSizeForLayoutSize(") ||
        input.startsWith("getFontSizeForLayoutSize(") ||
        input.startsWith("roundToNearestFontScale(") ||
        input.startsWith("platformColor(") ||
        input.startsWith("platform("));
}
exports.isRuntimeFunction = isRuntimeFunction;
function matchRuntimeFunction(input) {
    const matches = input.match(/(.+?)\((.*)\)/);
    if (!matches)
        return [undefined, undefined];
    return [matches[1], matches[2]];
}
exports.matchRuntimeFunction = matchRuntimeFunction;
