"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.platformSelect = void 0;
function platformSelect(value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    var _a;
    const isNative = process.env.NATIVEWIND_NATIVE_PLUGIN_ENABLED;
    if (isNative) {
        const platformParameter = Object.entries(value)
            .map((entries) => entries.join(":"))
            .join(" ");
        return `platform(${platformParameter})`;
    }
    else {
        return (_a = value.web) !== null && _a !== void 0 ? _a : value.default;
    }
}
exports.platformSelect = platformSelect;
